@font-face {
  font-family: Bebas Neue;
  src: url("../static/BebasNeue/BebasNeue-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

a {
  position: absolute;
  bottom: 0;
  left: 10px;
  color: #eee;
  font-family: "Bebas Neue";
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}
